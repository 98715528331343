import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../store/AuthContext";
import { loadme } from "../utils/api/auth";
import { Spinner, Alert, AlertIcon, AlertTitle, AlertDescription, Box } from "@chakra-ui/react";

export default function ProtectedRoute({ children }) {
  const location = useLocation();
  const { isAuthenticated, login } = useAuth();
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAuthAndAccess = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const accessToken = queryParams.get("access_token");
        const refreshToken = queryParams.get("refresh_token");

        if (accessToken && refreshToken) {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          await login();
        }

        const userData = await loadme();
        if (userData) {
          const now = new Date();
          const hasRoleSuperAdmin = userData.role && userData.role.includes("super_admin");

          const validSubscription = userData.subscriptions?.some(subscription => {
            const startDate = new Date(subscription.startDate);
            const endDate = new Date(subscription.endDate);
            return startDate <= now && now <= endDate && subscription.appAccess.includes("Administration_App");
          });

          setHasAccess(hasRoleSuperAdmin || validSubscription);
        } else {
          setHasAccess(false);
        }
      } catch (error) {
        console.error("Failed to load user data", error);
        setError("Failed to load user data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    checkAuthAndAccess();
  }, [location, login]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (error) {
    return (
      <Box p={5} maxWidth="400px" margin="auto">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </Box>
    );
  }

  if (!isAuthenticated) {
    window.location.href = "https://physioapp.celestialwavedigital.com";
    return null;
  }

  if (!hasAccess) {
    return (
      <Box p={5} margin="auto" className="w-fit">
        <Alert status="warning">
          <AlertIcon />
          <AlertTitle mr={2}>Access Denied</AlertTitle>
          <AlertDescription>
            It looks like you don't have an active subscription. Please check your account or subscribe to access this content.
          </AlertDescription>
        </Alert>
      </Box>
    );
  }

  return children;
}