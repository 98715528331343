import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-calendar/dist/Calendar.css";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./lib/theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
      </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
