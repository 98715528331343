import { extendTheme } from "@chakra-ui/react"

export const theme = extendTheme({
  config: {
    initialColorMode: 'light', 
    useSystemColorMode: false,
  },
  fonts: {
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif",
  },
  colors: {
    brand: {
      50: "#e6f0ff",
      100: "#b3d1ff",
      200: "#80b3ff",
      300: "#4d94ff",
      400: "#1a75ff",
      500: "#0056e6",
      600: "#0043b3",
      700: "#003080",
      800: "#001f4d",
      900: "#000d1a",
    },
    gray: {
      50: "#f8f9fa",
      100: "#f1f3f5",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#868e96",
      700: "#495057",
      800: "#343a40",
      900: "#212529",
    },
  },
  components: {
    input: {
      baseStyle: {
        field: {
          bg: 'white',
          _focus: {
            bg: 'white',
          },
        },
      },
    },
    Button: {
      baseStyle: {
        bg: 'blue.500',
        _hover: {
          bg: 'blue.600',
        },
        _active: {
          bg: 'blue.700',
        },
      },
    },
    Card: {
      baseStyle: {},
      variants: {},
      defaultProps: {},
    },
    Table: {
      baseStyle: {},
      variants: {},
      defaultProps: {},
    },
  },
})