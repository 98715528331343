import React, { lazy, Suspense, useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";


const Dashboard = lazy(() => import("../pages/global-view-dashboard/index.js"));
const AccountingDashboard = lazy(() => import("../pages/accounting-dashboard/index.js"));
const PharmacyDashboard = lazy(() => import("../pages/pharmacy-dashboard/index.js"));
const TaskManagementDashboard = lazy(() => import("../pages/task-management-dashboard/index.js"));
const DocumentManagementDashboard = lazy(() => import("../pages/document-management-dashboard/index.js"));
const SectorsManamgementDashboard = lazy(() => import("../pages/sector-management-dashboard/index.js"));
const TacticsDashboard = lazy(() => import("../pages/tactics-dashboard/index.js"));
const ProfileSettings = lazy(() => import("../pages/settings-dashboard/ProfileSettings.js"));
const PasswordSettings = lazy(() => import("../pages/settings-dashboard/PasswordSettings.js"));
const PlanSettings = lazy(() => import("../pages/settings-dashboard/PlanSettings.js"));
const HRManagementDashboard = lazy(() => import("../pages/hr-management-dashboard/index.js"));
const HRManagementSupervisorsDashboard = lazy(() => import("../pages/hr-management-dashboard/Supervisors.js"));
const HRManagementStaffDashboard = lazy(() => import("../pages/hr-management-dashboard/Staff.js"));
const HRManagementPlayersDashboard = lazy(() => import("../pages/hr-management-dashboard/Players.js"));
const HRManagementReportDashboard = lazy(() => import("../pages/hr-management-dashboard/Report.js"));
const HRManagementSalariesDashboard = lazy(() => import("../pages/hr-management-dashboard/Salaries.js"));
const AddPlayer = lazy(() => import("../pages/hr-management-dashboard/AddPlayer.js"));
const UpdatePlayer = lazy(() => import("../pages/hr-management-dashboard/UpdatePlayer.js"));
const InviteSupervisorsAndStaff = lazy(() => import("../pages/hr-management-dashboard/InviteSupervisorsAndStaff.js"));
const UpdateSupervisorsAndStaff = lazy(() => import("../pages/hr-management-dashboard/UpdateStaffAndSupervisors.js"));
// const Login = lazy(() => import("../pages/Login.js"));


export const ROUTES = {
  // LOGIN: "/login",
  DASHBOARD: "/",
  TASK_MANAGEMENT: "/task-management",
  ACCOUNTING: "/accounting",
  PHARMACY: "/pharmacy",
  HR: "/hr-management",
  DOCUMENT_MANAGEMENT: "/document-management",
  SECTOR_MANAGEMENT: "/sector-management",
  TACTICS: "/tactics",

  HR_REPORT: "report",
  HR_SUPERVISORS: "supervisors",
  HR_STAFF: "staff",
  HR_PLAYERS: "players",
  HR_INVITE_STAFF: "staff/invite",
  HR_EDIT_STAFF: "staff/edit/:id",
  HR_ADD_SUPERVISOR: "supervisors/add",
  HR_ADD_PLAYER: "players/add",
  HR_EDIT_PLAYER: "players/edit/:id",
  HR_SALARIES: "salaries", 

  SETTINGS: "/settings",
  SETTINGS_PASSWORD: "/settings/password",
  SETTINGS_PLAN: "/settings/plan",
};

export default function AppRoutes() {

  const protectedRoutes = useMemo(() => (
    <Routes>
      <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
      <Route path={ROUTES.TASK_MANAGEMENT} element={<TaskManagementDashboard />} />
      <Route path={ROUTES.ACCOUNTING} element={<AccountingDashboard />} />
      <Route path={ROUTES.PHARMACY} element={<PharmacyDashboard />} />
      
      <Route path={ROUTES.HR}>
        <Route index element={<HRManagementDashboard />} />
        <Route path={ROUTES.HR_REPORT} element={<HRManagementReportDashboard />} />
        <Route path={ROUTES.HR_SUPERVISORS} element={<HRManagementSupervisorsDashboard />} />
        <Route path={ROUTES.HR_STAFF} element={<HRManagementStaffDashboard />} />
        <Route path={ROUTES.HR_PLAYERS} element={<HRManagementPlayersDashboard />} />
        <Route path={ROUTES.HR_SALARIES} element={<HRManagementSalariesDashboard />} />
        <Route path={ROUTES.HR_ADD_PLAYER} element={<AddPlayer />} />
        <Route path={ROUTES.HR_EDIT_PLAYER} element={<UpdatePlayer />} />
        <Route path={ROUTES.HR_INVITE_STAFF} element={<InviteSupervisorsAndStaff />} />
        <Route path={ROUTES.HR_EDIT_STAFF} element={<UpdateSupervisorsAndStaff />} />
        <Route path={ROUTES.HR_ADD_SUPERVISOR} element={<InviteSupervisorsAndStaff />} />
      </Route>

      <Route path={ROUTES.DOCUMENT_MANAGEMENT} element={<DocumentManagementDashboard />} />
      <Route path={ROUTES.SECTOR_MANAGEMENT} element={<SectorsManamgementDashboard />} />
      <Route path={ROUTES.TACTICS} element={<TacticsDashboard />} />
      <Route path={ROUTES.SETTINGS} element={<ProfileSettings />} />
      <Route path={ROUTES.SETTINGS_PASSWORD} element={<PasswordSettings />} />
      <Route path={ROUTES.SETTINGS_PLAN} element={<PlanSettings />} />
    </Routes>
  ), []);

  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          {/* <Route path={ROUTES.LOGIN} element={<Login />} /> */}
          
          <Route
            path="*"
            element={
              <ProtectedRoute>
                {protectedRoutes}
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
