import axios from "axios";

export const login = async (email, password) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/login`,
      {
        email,
        password,
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Login failed:", error.response.data);
      throw new Error(error.response.data.message || "Login failed");
    } else if (error.request) {
      console.error("No response received:", error.request);
      throw new Error("No response received from server");
    } else {
      console.error("Error:", error.message);
      throw new Error("An error occurred during login");
    }
  }
};

export const logout = async () => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    throw new Error("No token found");
  }

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/logout`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  return response.data;
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    throw new Error("No refresh token found");
  }

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/refresh`,
    {},
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }
  );

  const { accessToken } = response.data.data;
  localStorage.setItem("accessToken", accessToken);

  return accessToken;
};

export const loadme = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("No access token found");
    }

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/auth/loadme`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.data;
  } catch (error) {
      console.error("Failed to fetch user role:", error);
  }
};

export const changePassword = async (currentPassword, newPassword) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("No access token found");
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/updatePassword`,
      {
        currentPassword,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Failed to change password:", error);
    throw new Error(error.response.data.message || "Failed to change password");
  }
};
